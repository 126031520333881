
  @import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,400&family=Source+Sans+3:wght@300;400;600;700&display=swap');
/* Body 
------------------------------------- */
body {
		background: #ffffff;
    font-family: 'Source Sans Pro', sans-serif;
    font-style: normal;
    font-weight: 300;
    position: relative;
}

html,body {
  width: 100%;
  overflow-x: hidden;
}

html {
  font-size: 100%;
}


/* Typography
------------------------------------- */

h1,h2,h3,h4 {
  font-weight: 400;
  letter-spacing: 0.3rem;
  text-transform: uppercase;
}

h1 {
  color: #000;
  font-size: 1.8rem;
  font-weight: 700;
}

h2 {
  color: #ffffff;
  font-size: 1rem;
}

h3 {
  color: #444;
  font-size: 14px;
  letter-spacing: 4px;
}

h5 {
  font-weight: 400;
  letter-spacing: 1px;
}

p {
  color: #777;
  font-size: 16px;
  /* line-height: 28px;
  word-spacing: 1px; */
  letter-spacing: 1px;
}

a {
  outline: none;
  text-decoration: none;
}
a,
a:hover,
a:focus {
  color: inherit;
  text-decoration: none;
  outline: 0;
  -webkit-transition: all 0.25s ease-out;
  -moz-transition: all 0.25s ease-out;
  -ms-transition: all 0.25s ease-out;
  -o-transition: all 0.25s ease-out;
  transition: all 0.25s ease-out;
}

img {
  width: 100%;
  max-width: 100%;
}

.logo-text{
  letter-spacing: 0.2rem;
}

.active{
  color: rgb(0, 0, 0) !important;
  font-weight: 600 !important;
}

/* header section styles
/* ========================================== */
#header {
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center center;
  text-align: center;
  padding-top: 130px;
  padding-bottom: 100px;
  min-width: 300px;
}

#header .header-thumb {
  background: #ffffff;
  border: 10px solid #f9f9f9;
  padding: 62px 20px 62px 20px;
}

.header-home .header-thumb,
.header-two .header-thumb,
.header-about .header-thumb,
.header-contact .header-thumb,
.header-blog .header-thumb {
  padding: 32px 20px 32px 20px !important;
}

.header-two,
.header-about, .header-contact,
.header-blog {
  height: 42vh !important;
  min-height: 420px;
  padding-top: 120px !important;
}

.header-home {
  min-height: 680px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  transition: background-image 1s ease;
}

.header-home.image-1 {
  background-image: url('../public/PortfolioImgs/Cover-Photo-01.jpg');
}

.header-home.image-2 {
  background-image: url('../public/PortfolioImgs/Cover-Photo-02.jpg');
}

.header-home.image-3 {
  background-image: url('../public/PortfolioImgs/Cover-Photo-04.jpg');
}

.header-home.image-4 {
  background-image: url('../public/PortfolioImgs/Cover-Photo-05.jpg');
}

.header-home.image-5 {
  background-image: url('../public/PortfolioImgs/Cover-Photo-06.jpg');
}

.header-home.image-6 {
  background-image: url('../public/PortfolioImgs/Cover-Photo-07.jpg');
}


/* Responsive adjustments for smaller screens */
@media (max-width: 767px) {
  .header-home {
    height: 760px;
    min-height: 420px; /* Adjust as needed */
    background-size: contain;
    padding-top: 80px; /* Adjust as needed */
  }
}

.header-about {
  background: url('../public/PortfolioImgs/Main.jpg') no-repeat;
  height: 60vh !important;
  min-height: 520px;
  padding-top: 150px !important;
}

.header-works {
  background: url('../public/PortfolioImgs/Main.jpg') no-repeat;
  height: 60vh !important;
  min-height: 520px;
  padding-top: 150px !important;
}
.header-news {
  background: url('../public/PortfolioImgs/Main.jpg') no-repeat;
  height: 60vh !important;
  min-height: 520px;
  padding-top: 150px !important;
}
.header-contact {
  background: url('../public/PortfolioImgs/Main.jpg') no-repeat;
  height: 60vh !important;
  min-height: 520px;
  padding-top: 150px !important;
}




/* Portfolio section styles
/* ========================================== */
#portfolio {
  text-align: center;
  padding-top: 3rem;
  padding-bottom: 6rem;
}

#portfolio img {
  width: 100%;
}

#portfolio .portfolio-thumb {
  position: relative;
  overflow: hidden;
  margin: 0;
}

#portfolio .portfolio-thumb .portfolio-overlay {
  background: #141414;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

#portfolio .portfolio-thumb:hover .portfolio-overlay {
  opacity: 0.9;
}

#portfolio .portfolio-thumb .portfolio-overlay .portfolio-item {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

/* single project section styles
/* ========================================== */
#single-project {
  padding-top: 4rem;
  padding-bottom: 6rem;
}

#single-project .project-info {
  padding-bottom: 3px
}

#single-project img {
  padding-top: 7px;
}

/* about section styles
/* ========================================== */
#about {
  padding-top: 5rem;
  padding-bottom: 8rem;
}

#about .col-md-4 {
  padding-top: 16px;
  padding-bottom: 32px;
}

#about img {
  padding-bottom: 20px;
}

#about blockquote {
  letter-spacing: 2px;
  margin-top: 20px;
}

/* blog & single post section styles
/* ========================================== */
#blog, 
#single-post {
  padding-top: 4rem;
  padding-bottom: 6rem;
}

#blog .blog-thumb {
  padding-top: 60px;
  padding-bottom: 40px;
}

#blog .post-format,
#single-post .post-format {
  padding-bottom: 10px;
}

#blog .post-format span,
#single-post .post-format span {
  color: #888;
  font-weight: 400;
  letter-spacing: 1px;
  padding-right: 10px;
}

#blog .blog-thumb .btn {
  border: 1px solid #000;
  padding: 10px 32px;
  border-radius: 0px;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-top: 22px;
  transition: all 0.4s ease-in-out;
}
#blog .blog-thumb .btn:hover {
  background: #111112;
  border-color: transparent;
  color: #ffffff;
}

#single-post blockquote {
  margin-top: 32px;
  letter-spacing: 2px;
}
.blockquote {
  padding: 10px 20px;
  margin: 0 0 20px;
  font-size: 17.5px;
  border-left: 5px solid #eee;
}

.post-image {
	margin: 25px 0px;
}

#single-post .blog-comment,
#single-post .blog-comment-form {
  margin-top: 62px;
}

#single-post .blog-comment h3,
#single-post .blog-comment-form h3 {
  padding-bottom: 20px;
}

#single-post .blog-comment .media {
  padding-bottom: 22px;
}

#single-post .blog-comment .media img {
  padding-right: 14px;
}

#single-post .blog-comment h4 {
  font-weight: 700;
}

#single-post .blog-comment-form .form-control {
  background: transparent;
  border: 1px solid #eee;
  border-radius: 0px;
  box-shadow: none;
  font-size: 16px;
  margin-bottom: 16px;
  transition: all 0.4s ease-in-out;
}

#single-post .blog-comment-form input[type="submit"] {
  background: #111112;
  border-color: transparent;
  color: #ffffff;
  height: 50px;
}

/* Define the styles for the image */
.img-responsive {
  max-width: 100%;
  height: auto;
  max-height: 780px; /* Set the maximum height to 780px */
}

/* Apply specific styles for screens smaller than 768px */
@media (max-width: 767px) {
  .img-responsive {
    /* Adjust styles for smaller screens if needed */
    max-height: 100%; /* Reset max-height for smaller screens if needed */
  }
}


/*filter css*/
.filter-wrapper {
    width: 100%;
    margin-bottom: 32px;
    overflow: hidden;
}

.filter-wrapper li {
    display: inline-block;
    margin: 4px;
}

.filter-wrapper li button {
    background-color: transparent;
    border: none;
    color: #666;
    font-size: 13px;
    font-weight: 400;
    letter-spacing: 2px;
    padding: 8px 17px;
    margin-right: 2px;
    margin-left: 2px;
    text-transform: uppercase;
    display: block;
    text-decoration: none;
    transition: all 0.4s ease-in-out;
}
.filter-wrapper li button:active {
  background: #111112 !important;
}

.filter-wrapper li button:hover,
.filter-wrapper li button:focus {
  background: #111112;
  color: #ffffff;
}

/*isotope box css*/
.iso-box-section {
    width: 100%;
    /* margin-left: 10%; */
}

.iso-box-wrapper {
  position: relative;
  width: 100%;
  padding: 0;
  clear: both;
}

.iso-box {
    position: relative;
    min-height: 50px;
    float: left;
    overflow: hidden;
    padding: 20px;
    /* margin-bottom: 25px; */
    /* margin-right: 20px; */
}

.iso-box > a {
    display: block;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.fluid-img {
    width: 100%;
    display: block;
}

/* contact section styles
/* ========================================== */
#contact {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

#contact .fa {
  font-size: 32px;
  padding-right: 10px;
}

#contact #map-canvas  {
  border: none;
  width: 100%;
  height: 300px;
  margin-top: 40px;
}

#contact .contact-form {
  padding-top: 14px;
}

#contact .form-control {
  background: transparent;
  border: 1px solid #eee;
  border-radius: 0px;
  box-shadow: none;
  font-size: 16px;
  margin-bottom: 16px;
  transition: all 0.4s ease-in-out;
}

#contact .form-control:hover {
  border-color: #f0f0f0;
}

#contact textarea {min-height: 100px;}

#contact input {height: 50px;}

#contact input[type='submit'] {
  background: #111112;
  color: #ffffff;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 2px;
  transition: all 0.4s ease-in-out;
  margin-top: 10px;
}

#contact input[type='submit']:hover {
  background: #444;
}

.contact-submit {
	width: 50%;
}

#contact .col-md-4 {
  background: #fff;
  padding: 5px 0px 20px 15px;
  position: relative;
  bottom: -50px;
}

.custom-image-gallery {
  width: 100%; /* Set a fixed width */
  max-height: 500px; /* Set a fixed max-height for the gallery */
  overflow: hidden; /* Hide any overflowing content */
}
.custom-image-gallery .image-gallery-image {
  width: auto; /* Allow the image to adjust its width */
  max-height: 100%; /* Take up the full available height */
  object-fit: contain; /* Maintain aspect ratio and fit within the container */
}


/* footer section styles
/* ========================================== */
footer {
  background: #141414;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
  color:#acacac;
  /* height: 10px; */
}


/* social icon */
.social-icon {
  padding: 0;
  margin: 0;
}
.social-icon li {
  list-style: none;
  display: inline-block;
}
.social-icon li a {
  border: 1px solid transparent;
  color: #acacac;
  font-size: 18px;
  width: 50px;
  height: 50px;
  line-height: 48px;
  text-align: center;
  vertical-align: middle;
  text-decoration: none;
  transition: all 0.4s ease-in-out;
  padding: 0px;
  margin-top: 14px;
  margin-right: 8px;
  margin-left: 8px;
  position: relative;
  top: 0px;
}
.social-icon li a svg{
  padding: 10px;
  border-radius: 50%;
  box-sizing: content-box;
}
.social-icon li a svg:hover {
  background: #ffffff;
}
/* WorkDetails.css */

/* Define a class for the image feed container */
.image-feed {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; /* Left-align images horizontally */
}

/* Center-align images vertically */
.image-item {
  display: flex;
  align-items: center;
}

/* Projects.css */
.loading-animation {
  width: 50px;
  height: 50px;
  border: 5px solid #ccc;
  border-top: 5px solid #3498db;
  border-radius: 50%;
  animation: spin 2s linear infinite;
  margin: 0 auto;
}

.custom-link {
  color: Grey;
  text-decoration: underline;
}

.custom-link:hover {
  text-decoration: none; /* Hides the underline on hover */
}

.capitalize-first-letter {
  text-transform: capitalize;
  color: rgb(76, 76, 76);
}

.container {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.content {
  margin-bottom: 20px;
}

.title {
  font-size: 24px;
}

.description {
  font-size: 18px;
}

.image-container img {
  width: 100%;
  height: auto;
}

.image-row img {
  width: 48%;
  height: auto;
  margin-bottom: 10px;
}

.info {
  padding: 20px 0;
  text-align: center;
  font-size: 18px;
}


@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* For large screens (laptops or larger) */
.demo-total{
  padding-Top: 200px;
  padding-Left: 100px;
  padding-Right: 100px; 
  width: '100%'
}

.demo-title{
  padding-right: 500px;
  padding-left: 70px;
  padding-top: 200px;
}

.demo-text{
  padding-left: 200px;
  padding-right: 200px;
  padding-top: 200px;
  padding-bottom: 200px;
}

.demo-text-first{
  padding-right: 500px;
  padding-bottom: 100px;
  padding-left: 70px;
}

.image {
  width: 100%; /* Set the width to 100% of the container */
  max-width: 100%; /* Set the maximum width to 100% of the container */
  height: 100%; /* Maintain the aspect ratio */
}

/* For smaller screens (mobile devices) */
@media screen and (max-width: 767px) {

  .image {
    min-width: 100vw; /* Remove the maximum width for smaller screens */
    height: auto; /* Maintain the aspect ratio */
    display: flex;
    flex-direction: column; 
    margin: 15px 0px 15px 0px;
    padding-left: -110%;
    padding-top: 0%;
 }

 .demo-total{
  padding-top: 15px;
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
 }
 
 .demo-text{
  padding-left: 0px;
  padding-right:0px;
  padding-top: 0px;
  padding-bottom: 0px;
 }

 .demo-title{
  padding-right: 0px;
  padding-left: 0px;
  padding-top: 0px;
}

.demo-text-first{
  padding-right: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
}
}
